@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Manrope:wght@200..800&display=swap');
:root{
  --primary-color: #74b652;
 
}
a {
  color: inherit;
  text-decoration: none;
}
body, html{
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Manrope", sans-serif;
}
p {
  font-size: 15px;
}
.kinderbtn img {
  width: 60px;
}



.contact-form input {
  width:100%;
  height: 40px;
  border: solid 1px #ddd;
  padding-left: 10px;
  float: left;
  margin-right: 10px;
  margin-bottom: 20px
}

.contact-form input.fullwidth {
  width: 98%;
  margin-right: 0
}

.contact-form input.msg {
  width: 98%;
  margin-right: 0;
  height: 60px
}

.contact-form input[type=submit] {
  width: max-content;
  padding: 0 20px;
  margin-top: 10px;

}

.contact-form input[type=submit]:hover {
  background: #f5ccbe
}

.contact-form select {
  width: 48%;
  height: 40px;
  border: solid 1px #ddd;
  padding-left: 10px;
  float: left;
  margin-right: 10px;
  margin-bottom: 20px;
  color: #757782
}

.contact-form textarea {
  width: 100%;
  padding-top: 10px;
  resize: none;
  outline: none;
  border: solid 1px #ddd;
  padding-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px
}



.responsive-number a {
  display: inline-block; /* Ensures each number appears in its own block */
}



.kinderbtn {
  background: linear-gradient(180deg, #cf30a0, #7c175b);
  padding: 12px;
  text-align: center;
}

.kinderbtn span {
  display: block;
  color: #fff;
  font-size: 14px;
}
.button {
  background: linear-gradient(180deg, #7a0d5a, #8c2a6f8c);
  width: max-content;
  padding: 5px 7px 5px 19px;
  border-radius: 40px;
  border:3px solid #b5338f;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  font-weight: 300;
  box-shadow: 1px 6px 5px -2px #0003;
}
.button:before {
  content: "";
  position: absolute;
  width: 180px;
  height: 180px;
  left: 50%;
  top: 50%;
  background: linear-gradient(180deg, #7d125e, #f3c6e400);
  transform: translate(-50%, -50%) rotate(0deg);
  transition: 0.5s;
  /* z-index: -1; */
}

.button:hover:before {
  transform: translate(-50%, -50%) rotate(180deg);
}
.button .img {
  border: 1px solid #97407c;
  padding: 4px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 13px;
  box-shadow: 0px 3px 5px 0px #00000038;
  position: relative;
}
.button span{
  position: relative;
}
.button:hover {
  background: linear-gradient(180deg, #7a0d5a, #f3c6e4);;
}
.button img{
  position: relative;
}
.header-blk {
  width: 95%;
  margin: auto;
}
.logo { 
  padding-left: 30px;
}
.logo img {
    width: 260px;
    margin-top: 10px;
}
.contact-tab {
  display: flex;
  width:100%;
  justify-content: flex-start;
  margin-left: 70px; 
  padding-right: 60px;
  align-items: center;
}
.contact-tab>div {
  margin-right: 40px;
}
.contact-tab .button {
  margin: 10px;
  font-size: 13px;
}

.button.padding {
  padding: 12px 17px;
}
.menu-tab{
  background: url(https://webapi.entab.info/api/image/TPSP/public/Images/menu-bg.svg);
  background-size: 110% 110%;
    background-position: center;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    position: absolute;
    right: 30px;
    z-index: 999;
    top: 38px;
}
button.menu-toggle {
  transition: 0.5s;
  border: 0px;
  background: transparent;
}
.d-flex.justify-content {
  justify-content: center;
}
.menu-tab:hover {
  transform: rotate(90deg);
}
.menu-tab:hover button.menu-toggle {
  transform: rotateY(180deg) rotate(90deg);
}
.contact-tab .button.light {
  background: linear-gradient(10deg, #2790c7, #b7e0f5);
  border: 3px solid #92d5f7;
}
.contact-tab .button.light:before{
  background: linear-gradient(10deg, #2790c7, #b7e0f5);
  width: 130px;
    height: 130px;
}
.menu > div{
  position: fixed;
  background: #fff;
  width: 100%;
  min-height: 100vh;
  top: 0;
  right: 0;
  z-index: 99; 
}
.header {
  padding: 1px 0 1px;
  border-bottom: 5px solid #5eaed7;
}
.pd-0{
  padding: 0;
}

.main-slider .owl-nav {
  position: absolute;
}
.abouttit {
  display: flex;
  justify-content: center;
}
.main-slider button.owl-prev {
  position: absolute;
  left: -10px;
  background: linear-gradient(92deg, #ff000000, transparent) !important;
  top: 49%;
  transform: translate(-0%, -50%);
  width: 100px;
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  transition: 0.5s;
}
.main-slider button.owl-next{
  position: absolute;
 
  background: linear-gradient(92deg, #ff000000, transparent) !important;
  top: 49%;
  transform: translate(-0%, -50%);
  width: 100px;
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  transition: 0.5s;
  right: -10px;
}
.main-slider button.owl-prev:hover  {background: linear-gradient(92deg,  #2499a0a1, #2499a054,transparent) !important;}
.main-slider button.owl-next:hover {background: linear-gradient(92deg, transparent, #2499a054, #2499a0a1) !important;}

.main-slider .owl-nav {
  position: static;
  height: 100%;
  width: 100%;
  top: 0;
}

.main-slider button.owl-prev span, .main-slider button.owl-next span {
  color: #ffffff94;
  font-size: 4rem;
  line-height: 0px;
  border: 2px solid #ffffff94;;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
  border-radius: 50%;
} 
.slide-content span {
  color: #be519d;
}
.main-slider .slide-content {
    position: absolute;
    left: 10%;
    bottom: 5%;
    background: #0000006b;
    padding: 20px 20px;
    color: #fff;
    text-transform: uppercase;
    border-radius: 14px;
}
.main-slider .slide-content h2 {
  font-size: 43px;
}
.main-slider img {
    height: 793px;
}
/* .main-slider .slide-content span{
  color: var(--primary-color)
} */
.main-slider .owl-dots{
  display: none;
}
.social-media {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9
}

.social-media img {
  width: 46px;
  margin: 4px 5px 0;
}
.highlihgt {
  background: linear-gradient(180deg, #2e2e2e, #2e2e2e,#2e2e2e,#2e2e2e, #2e2e2e);
  color: #fff;
  margin-top: -10px;
  padding: 5px;
  padding-bottom: 0;
  display: flex;
  padding-left: 180px;
  position: absolute;
  width: 100%;
  bottom: 9px;
  z-index: 9;
} 
 
.highlihgt .icon img {
  width: 44px;
  float: left;
  margin-top: -13px;
  z-index: 9;
  position: relative;
}
.highlihgt .icon {
  height: 29px;
}
.highlihgt p {
  display: inherit;
  margin: 0 20px;
}
.title{
  text-align: center;
  margin-bottom: 20px;
}
.title p{margin-bottom: 0;letter-spacing: 2px;color: #666666;}
.title h3{
  color: #333333;
  font-weight: 700;
}
.title h3 span{
  color: #333333;
  font-weight: 700;
  }
  .about-virtual{
    background: url(https://webapi.entab.info/api/image/TPSP/public/Images/about-bg.png);
    padding: 20px 20px 40px 20px;
    background-size: 100% 100%;
    position: relative; 
    color: #fff;
  }
  .about-virtual button { 
    background:linear-gradient(65deg, #458baf, #7bbfe3);
    color: #fff;
    padding: 5px 14px;
    border-radius: 5px;
    border: 0px;
    transition: 0.5s;
}
.about-virtual button:hover {
  background: #a82682;
  /* color: #000; */
}
.about-virtual .text h3 span {
  font-weight: 600;
  display: block;
  text-align: center;
}
.about-virtual .text h3 {
    margin: 30px 0;
    font-weight: 300;
}
.about-us .title {
  margin-top: 10px;
  margin-bottom: 10px;
}
 
.about-us .title div>span {
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 1.2px;
}
  .title h3 {
  font-weight: 300;
  color: #a82682;
}
.about-us p {
  line-height: 30px;
  color: #333333d4;
  text-align: left;
}
 .aboutimg  {
  display: flex; 
}
.aboutimg img {
  padding-bottom: 20px;
  height: 410px;
  width: 100%; 
}

  .text {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* background: #00000070; */
    width: 97%;
    height: 81%;
    /* top: 0; */
    transform: translate(-50%, -50%);
    left: 50%;
    top: 45%;
}

 
 
.about-virtual > button {
  position: absolute;
  right: 30px;
  top: 30px;
  display: none;
}
.about-us {
  text-align: center;
  padding: 50px 0;
  background: url(https://webapi.entab.info/api/image/TPSP/public/Images/about-background.png); 
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.about-us .button {
  margin: auto;
  /* display: block; */
}
.video-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9); /* Dark background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.video-popup-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.close-button {
  border-radius: 70px;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 50px;
  color: #000;
  background: #fff;
  border: none;
  cursor: pointer;
  z-index: 1001;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
video {
  max-width: 98%;
  max-height:98%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.facilities{
  background: url(https://webapi.entab.info/api/image/TPSP/public/Images/facilities.png); 
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 50px 0px;
  background-position: center;
}
.facilities > div{
  width: 88%;
  margin: auto;
}
.facil-blk {
  text-align: center;
  margin-top: 30px;
}
.facil-blk p {
  color: #666666;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.facil-blk img {
  width: 71px !important;
  margin: auto;
}

.facil-blk h3 {
  font-size: 19px;
  margin: 20px 0;
}
.facilities-events .title {
  display: flex;
  justify-content: space-between;
}
.facilities-events .title.center >div:nth-child(1) {
  width: 90%;
  text-align: center;
  padding-left: 120px;
}
.imp-link{
  background: url(https://webapi.entab.info/api/image/TPSP/public/Images/implink.jpg); 
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding:30px 0px 30px;
  background-position: center;
  margin:40px 0;
}
.imp-link img {
  width: 50px;
  position: relative;
  z-index: 9;
}

.imp-link .d-flex {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.imp-link .imp-img {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: relative;
}

.imp-link .imp-img:after {
  content: "";
  width: 100%;
  height: 100%;
  border:2px solid #52a12a;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px; 
  background-clip: border-box;
  padding: 2px; /* Match the width of the border */
  box-sizing: border-box; /* Include padding in width and height */
  animation: 15s rotate infinite;
}
.contact-header {
  position: absolute;
  right: 110px;
  bottom: 5%;
  z-index: 9;
  background: #ffffff87;
  padding: 10px;
  border-radius: 10px;
}

.container-fluid.min-ht {
  position: relative;
}

.contact-header img {
  width: 39px;
  margin-right: 8px;
}

.contact-header a {
  display: block;
  /* color: #fff; */
}
.imp-link .imp-img:before {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(to right,#ffffff, #ffffff);  
  transform: rotate(45deg);
  transform-origin: center;
  position: absolute;
  border-radius: 10px;
  
  animation: 20s rotate infinite;
  z-index: 9;    
}
img.anim-icon {
  animation: 2s animate-left infinite alternate;
}
@keyframes animate-left{
  0%{
      transform: rotate(0deg)
  }
  100%{
      transform: rotate(10deg)
  }
}
@keyframes rotate{
  0%{
      transform: rotate(0deg)
  }
  100%{
      transform: rotate(360deg)
  }
}
.imp-link h4 {
  color: #333;
  margin-top: 30px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
}
.mar-0{
  margin: 0
}
  .button.padding.arrow {
    background: no-repeat;
    border: none;
    box-shadow: none;
}
.button.padding.arrow:before {
  display: none;
}

.button.padding.arrow .img.mar-0 {
    background: linear-gradient(180deg, #7a0d5a, #f3c6e4);
    width: 50px;
    height: 50px;
    transition: 0.5s;
}
.button.padding.arrow .img:hover {
  /* margin-bottom: -5px; */
  box-shadow: 0px 3px 5px 0px #000000ab;
}
.news-event .title {
  display: flex;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.news-event .title.center >div:nth-child(1) {
  width: 90%;
  text-align: center;
  padding-left: 120px;
}

.news-event marquee .button.padding.arrow  .img{
  background: linear-gradient(10deg, #5eaed7, #89cef1);
  border: 3px solid #8ad1f5;
  width: 40px;
  height: 40px;
  transition: 0.5s;
}
.news-event marquee .button.padding.arrow .img:hover {
  background:linear-gradient(10deg, #5eaed7, #89cef1);
  box-shadow: 1px 4px 17px -12px #000;
}

/* .message-block .button.light.padding:before, .branch .button.light.padding:before {
  display: none;
} */
 
.newsarea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.newsarea > div {
  border-bottom: 1px solid #ddd;
  /* margin-bottom: 20px; */
  width: 100%;
  padding-bottom: 13px;
}

.newsarea  p { 
  margin-bottom: 3px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.evt-blk {
  border: 1px solid #f6e7b1;
  border-radius: 11px;
  padding: 5px;
  text-align: center;
  position: relative;
  background: #f6e7b1;
  margin-right: 10px;
}
.evt-img img {
  height: 200px;
  border-radius: 5px;
  width: 100%;
}
.evt-blk p.date {
  width: max-content;
  text-align: center;
  position: absolute;
  top: 20px;
  left: 20px;
  background: #00000057;
  padding: 8px 10px;
  border-radius: 9px;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 1px;
  display: unset;
  font-size: 14px;
  line-height: 23px;
}

.evt-blk p.date span {
  display: block;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0;
}

.readmore {
  width: max-content; 
  background: linear-gradient(10deg, #7a0d5a, #f3c6e4);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 3px;
  color: #fff;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  font-size: 13px;
  top: 34%;
  opacity: 0;
  transition: 0.5s;
}
.news-blk marquee {
  height: 218px;
}
.readmore img {
  width: 15px !important;
  height: auto;
  margin-left: 14px;
}
.evt-title h3 {
  font-size: 17px;
  width: 100%;
  margin: 20px auto 10px;
  color: #333333;
}
.evt-blk:hover .readmore {opacity: 1;}
.news {
  background: #F5F5F5;
  padding: 40px 20px;
  border-radius: 16px;
  box-shadow: 0px 35px 36px -50px #000;
  margin-bottom: 20px;
  position: relative;
  z-index: 9;
}
.newsbg:after ,.achievbg:after {
  position: absolute;
  content: ' '; 
  width: 100%; 
  height: 100%; 
  top: 0;
  z-index: -1;
  right: 0; 
  background-size: contain; 
  opacity: 0.6;
}
.newsbg:after { 
  background: url(https://webapi.entab.info/api/image/TPSP/public/Images/newspaperbg.png) right center no-repeat; 
  background-size: contain;
}
.achievbg:after { 
  background: url(https://webapi.entab.info/api/image/TPSP/public/Images/badgebg.png) right center no-repeat; 
  background-size: contain;
}
.events {
  background: #F5F5F5;
  padding: 40px 20px;
  border-radius: 16px;
  width: 98%;
  margin-left: auto;
  box-shadow: 0px 43px 40px -55px #000;
}
.news-event > div {
  width: 88%;
  margin: auto;
}
.evt-img p, .evt-img h3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.message-block {
  display: flex;
  align-items: flex-start;
}
.message-block .message-image img {
  width: 100%;
}
.message-block .message-image {
  width: 12%;
  margin: 9px 0 9px 5px;
  border: 10px solid #fff;
  box-shadow: -2px 0px 5px -2px #bdbdbd;
} 
.message > div,.about-us > div  {
  width: 90%;
  margin: auto;
}
.message .title {
    text-align: right;
    text-transform: uppercase;
}
.message{
  position: relative;
  background: url(https://webapi.entab.info/api/image/TPSP/public/Images/gallery-bg.png);
  background-size: 100%;  
  padding: 70px 0 30px;
}

.message-content {
  width: 88%;
  height: 350px;
  padding: 30px;
  box-shadow: 0px 43px 40px -55px #000, 0px 0px 5px 0px #e5e5e5;
  margin: 10px 0;
  margin-right: 5px;
  margin-bottom: 40px;
  background: #fff;
  position: relative;
}
img.quotes {
  position: absolute;
  right: 0;
  top: 0;
  width: 71px !important;
}
 

.princi-Name {
  
  position: relative;
  margin-bottom: 20px;
}

.princi-Name:after {
  background: linear-gradient(45deg, #0880bf, #7cb6d5);
  width: 20%;
  height: 6px;
  content: "";
  position: absolute;
  left: 0;
  bottom: 9%;
  transform: translate(-0%, -50%);
}
.princi-Name h3 {
  font-weight: 600;
  font-size: 22px;
}
.princi-Name p {
  width: max-content;
  position: relative;
  margin-bottom: 0;
  background: #fff;
  z-index: 9;
  padding-right: 15px;
    text-transform: uppercase;
    color: #a82682;
  letter-spacing: 4px;
}
.message-block .button.light.padding , .branch .button.light.padding {
  background: linear-gradient(10deg, #6aaed2, #65aace);
  border: 0px solid #69add1;
    border-radius: 0 !important;
    padding: 4px 4px;
    position: absolute;
    z-index: 9;
    right: 60px;
    transition: 0.5s;
    text-transform: capitalize;
}
.message-block .button.light.padding,.branch .button.light.padding {
  padding: 9px 16px;
  bottom: -18px
}
.message-block .button.light.padding:before, .branch .branch-blk .button.light.padding:before {
  background: linear-gradient(65deg, #458baf, #7bbfe3);
}
.message-block .button.light.padding  img {
    width: 18px;
    margin-left: 8px;
    border-radius: 0 !important;
}
.message .about-virtual { 
  margin-top: 90px;
  height: 79%;
}
.videobg {
  height: 100%;
  width: 100%;
}
.gal-img .description {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 9;
  bottom: 0;
  flex-direction: column;
  padding: 10px;
  transition: 0.5s;
  background: #000000cf;
  margin-bottom: -22px;
  height: 65px;
}
 

.gal-img .description button {
  padding: 5px 10px 3px;
  background: var(--main-color);
  color: #fff;
  border-radius: 9px;
  border: 1px solid #fff;
  font-size: 13px;

}

.gal-img:hover .description {
  border-radius: 8px;
  margin-bottom: 0px;
  height: 100%;
}

.gal-img:hover .description span{
  opacity: 1;
}
.gal-img {
  position: relative;
  transition: 0.5s;
  margin:25px 0; 
  overflow: hidden;
  border-radius: 9px;
  height: 270px; 
}
.gal-img img {
  height: 270px;
  width: 100%;
}
.gal-img .description h3 {
  margin: 0;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 2px; 
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.news-event {
  padding: 70px 0px;
}
.gallery { 
  position: relative;
  background: url(https://webapi.entab.info/api/image/TPSP/public/Images/gallery-bg.png);
  background-size: 100%;
  padding-bottom: 140px;
  
}
 
.gallery .news-event .title {
  width: 100%;
}
 
.galsec {
  display: flex;
  justify-content: center;
  align-items: center;
}
.gallery .owl-nav.disabled button {
  color: #fff !important;
  background: #026a901a !important;
  border: 1px solid var(--main-color) !important;
  color: var(--main-color) !important;
}

.gal-img .description img {
  width: 30px !important;
}
.gallery > div {
  width: 88%;
  margin: auto;
}
footer {
  background: linear-gradient(0deg,#87326d,#d378b5fc);
  padding: 50px 0 10px;
  border-top: 5px solid #5eaed7;
}
.footer img {
  margin: auto;
  display: block;
  width: 150px;
}
.footer {
  color: #fff;
}
.branch-blk p {
  display: flex;
  text-align: left;
}
.quick-link ul {
  display: flex;
  flex-direction: column;
  height: 200px;
  flex-wrap: wrap;
  width: 35%;
  padding: 0;
  margin-bottom: 0;
}
.app-cont img.img-fluid {
  width: 100px;
  padding: 0px 7px 0px 0px;
}

.app-cont>div {
  display: flex;
  margin-bottom: 10px;
} 
.guideheading i {
    font-size: 6px;
    position: relative;
    top: -3px;
  }
  .mobileappimg img {
    width: 310px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
p.link {
  margin-bottom: 0;
  margin-top: 5px;
  background: #5ba0c4;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  width: max-content;
}
.app-cont {
  width: 65%;
}
.quick-link ul li a {
  color: #fff;
  text-decoration: none;
}

.quick-link ul li {
  list-style: none;
  position: relative;
  padding-left: 20px;
  margin-bottom: 11px;
  font-size: 14px;
  font-weight: 300;
}

.quick-link ul li:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 7px solid #fff;
  border-left: 4px solid #fff0;
  border-right: 4px solid #fff0;
  position: absolute;
  left: 0;
  top: 7px;
  transform: rotate(-90deg);
}
.footer h3 {
  font-size: 20px;
  margin-bottom: 20px;
}
.branch .branch-blk {
  text-align: center;
  background: #fff;
  color: #333333;
  padding: 20px;
  z-index: 9;
  position: relative;
  width: 83%;
  margin-bottom: 40px;
  box-shadow: -16px 15px 14px -17px #00000091;
  margin-left: 15px;
  margin-top:55px;
  border-radius: 10px;
}
.branch .branch-blk h4 {
  color: #5fa4c8;
}

.branch i {
  display: block;
    color: #67134d;
    font-size: 20px;
    display: block;
    text-align: center;
    margin-right: 10px;
}
.branch .button.light.padding img {
  width: 16px;
  margin-left: 10px;
}

.branch .branch-blk a {
  display: block;
}

.branch .branch-blk .button.light.padding {
  transform: translate(-50%, 46%);
  /* display: block; */
  margin: auto;
  bottom: 0;
  top: unset;
}
.branch {
  margin-top: -170px;
}
.branch .owl-nav {
  display: block;
  margin-top: 50px;
  position: absolute;
  right: 70px;
  top: 60px;
  /* width: 20px; */
  /* height: 20px; */
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.branch .owl-nav button {
  width: 28px;
  height: 30px;
  background:#811b63 !important;
  border-radius: 50% !important;
  font-size: 27px !important;
  line-height: 0 !important;
  box-shadow: inset 0px 0px 5px 0px #00000036;
}

.branch .branch-blk h4 {
  text-align: center;
  margin-bottom: 22px;
  padding-right: 70px;
  font-weight: 600;
}
.branch-blk h4+ p {
  font-size: 19px;
  font-weight: 300;
  color: #000;
  text-align: center;
  display: block;
}
.quick-link {
  border-right: 2px solid #ffffff87;
  display: flex;
}

footer iframe {
  margin-top: 0px;
  height: 230px;
  border-radius: 15px;
  box-shadow: 0px 0px 5px 0px #6c6c6c;
  border: 5px solid #fff;
}
.copyright p {
  margin: 0;
  text-align: center;
  background: #a82682;
  color: #fff;
  font-size: 12px;
  padding: 5px;
  font-weight: 300;
  letter-spacing: 1px;
}

/* 
.menu { 
  list-style-type: none;
  padding: 0;
  position: fixed;
  bottom: 0;
  right: 0;
  background: #fff;
  border: 1px solid #ccc;
  z-index: 1;
  border: none;
  flex-direction: column;
  padding: 20px 20px;
  width: 100%; 
  box-shadow: -1px 1px 5px 0px #000000a1;
  z-index: 99;
  height: 100%;
} */
.sub-submenu li:hover {
  color: var(--primary-color);
}
ul.submenu {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
  margin-top: 60px;
}
a:hover{
  color: inherit;
}
ul.submenu > li {
  width: 25%;
  margin-bottom: 40px;
  list-style: none;
  text-transform: uppercase;
  color: #a82682;
  font-weight: 600;
  font-size: 21px;
}

.menu .logo {
  width: 80%;
  margin: auto;
}

.menu .logo img {
  width: 180px;
  margin: auto;
  display: block;
  margin: 120px auto 40px auto;
}

ul.submenu > li ul {
  color: #333;
  padding: 0;
  list-style: none;
  margin-top: 20px;
}

ul.submenu > li ul li {
  margin-top: 9px;
  font-weight: normal;
  font-size: 14px;
}

ul.submenu li:hover {
  color: var(--primary-color);
}
  
.innerSection {
  padding: 50px 0 100px;
}
.breadcrumb {
  min-height: 250px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: url(https://webapi.entab.info/api/image/TPSP/public/Images/about.jpg); 
  position: relative;
  background-attachment: fixed;
  background-size: 100% auto;
  background-position: top;
}
.breadcrumb ul li {
  margin: 0 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}
.breadcrumb::before {
  content: "";
  width: 100%;
  height: 100%;
  background: #00000085;
  left: 0;
  top: 0;
  position: absolute;
}

.breadcrumb ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: relative;
}
.message .owl-carousel {
  display: block; 
}
.message .owl-dots {
  display: none;
}
.homemsgdesc{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp:6;
  overflow: hidden;
}
.form-section {
  position: fixed;
  z-index: 9;
  background: #0000003b;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  z-index: 99;
}
.form-section.active {
  display: flex;
}
.visible{  display: flex;}
.form-section .form-block {
  background: #fff;
  padding: 30px;
  width: 605px;
  border: 4px solid #a82682;
  border-radius: 14px;
  box-shadow: 0px 0px 20px 8px #0000005c;
  max-width: 95%;
}

.form-heading {
  width: 100% !important;
  text-align: center;
  margin-bottom: 40px;
  text-transform: uppercase;
  color: #a82682;
}
.button.menubtn {
  z-index: 999;
}
.form-section .form-block .form-feilds label {
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  margin-top:15px;
  margin-bottom: 5px;
}

.form-section .form-block .form-feilds {
  display: flex;
  flex-wrap: wrap;
}

.form-section .form-block .form-feilds > div {
  width: 50%;
}

.form-section .form-block .form-feilds > div input, .form-section .form-block .form-feilds > div select, .form-section .form-block .form-feilds > div textarea {
  width: 97%;
  padding: 5px;  
  border: 1px solid #ddd;
} 
p.success-message {
  color: green;
  text-align: center;
}
.message-feild {
  width: 100% !important;
}

.submit {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit input {
  width: max-content !important;
  margin: 20px;
  min-width: 100px;
  /* margin: auto; */
}
.form-section .form-block .form-feilds > div input[type="submit"] {
  background: linear-gradient(10deg, #387519, #74b652);
  color: #fff;
  border-radius: 5px;
  letter-spacing: 1px;
}
.close {
  float: right;
  font-weight: 800;
  font-size: 20px;
  margin-top: -11px;
}
.contact-blk {
  display: flex;
  /* align-items: center; */
  background: #2499a0;
  color: #fff;
  margin-bottom: 20px;
  box-shadow: 0px 19px 15px -20px #000;
  border-radius: 7px;
}

.contact-blk img {
  width: 40px;
}

.contact-blk .contact-info h6 {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #ffc799;
  font-size: 19px;
}

.contact-blk .contact-icon {
  margin-right: 20px;
}

.contact-blk .contact-info p {
  margin-bottom: 0;
}

.contact-blk .contact-icon {
  padding: 20px;
  background: #ededed;
  display: flex;
  align-items: center;
}

.contact-blk .contact-info {
  padding: 17px;
}
.innerSection iframe {
  border: 15px solid #fff;
  box-shadow: 0px 0px 5px #ddd;
}
.close, .online-reg {
  cursor: pointer;
}
.table-responsive table {
  width: 100%;
}

.table-responsive table td {
  padding: 9px;
  border: 1px solid #ddd;
}

.table-responsive table thead {
  background: #2499a0;
  color: #fff;
}

.table-responsive table thead th {
  font-weight: 400;
  padding: 9px;
  text-transform: uppercase;
}
.about-us .about-logo {
  width: 80px;
}
.galsecimg .anim-icon {
  width: 70%;
}


.chooseyear {
  width: max-content;
  float: right;
  margin-bottom: 30px;
  margin-top: -45px;
}
    #chooseddlYear option { color: black;}
    .chooseyear  select{margin:0px;}
    .chooseyear  select{width:100%;background: unset;border: unset;height:28px; margin-top:15px;border: unset;border-bottom: 1px solid grey;}
    .clr{clear:both} 
    .video-gallery {
      padding: 20px;
      box-shadow: 0px 1px 4px 1px #03030330;
      margin-bottom: 20px;
      background:#a65a8f21;
      border-radius: 3px;
  }
    .video-gallery a {
      display: flex;
      color: inherit;
      align-items: center;
    }
    .video-gallery a i {
     font-size: 40px;
     color: #ad0807;
     margin-right: 10px;
     display: flex;
    }
    .video-gallery a h3 {
      font-size: 16px;
      margin-bottom: 0;
      line-height: 24px;
      padding-top: 0;
      padding-bottom: 0;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      margin-top: 0;
      padding-left: 0;
  }
    .video-gallery p {
      font-size: 12px;
      margin-bottom: 0;
    }
    .video-gallery a h3:after {
      position: static;
    }
    .video-gallery a img {
      width: 52px;
      margin-right: 10px;
    }
    
.row.tabs-dynamic {
  background: #cbdcf1;
  padding: 20px;
  margin: 0px 0px 40px 0px;
}
.row.tabs-dynamic p {
  margin-bottom: 0;
}
.row.tabs-dynamic p a {
  color: #585757;
}
.row.tabs-dynamic select {
  width: 100%;
  border: 0px;
  background: transparent;
  padding: 8px 5px;
  border-bottom: 1px solid #b6b6b6;
}
.row.tabs-dynamic input {
  padding: 9px 5px;
  width: 100%;
  border: 0px;
  border-bottom: 1px solid #b6b6b6;
  background: transparent;
}
.count-val p {
  margin-bottom: 0px;
}

.news-head {display: flex;align-items: center;}
.news-head h2 {    padding-right: 20px; border-right: 1px solid #c5c5c5;  margin-right: 20px;  background: #e5e5e5; padding: 4px 10px;}
.news-head h3 {
    font-weight: 400;
    font-size: 22px;
    margin-top: 0;
    margin-bottom: 14px;
    color: #00599b;
    position: relative;
}
.innerSection .news-blk {height: 190px;margin-bottom: 20px;padding-right: 30px;padding-bottom: 30px;width: 97%;box-shadow: 9px 11px 5px 0px #dddddd3d;position: relative;overflow: hidden;padding: 20px;box-shadow: 0px 0px 12px 0px #e7e7e7;border: 4px double #b5b5b5;transition: 0.5s;}
.innerSection .news-blk:before {
    content: "";
    position: absolute;
    top: -40px;
    right: -8px;
    width: 100px;
    height: 100px;
    background: #ecf9ff;
    border-radius: 50%;
}
.attachment {
    background: #891c69;
    text-align: right;
    position: absolute;
    right: 0;
    bottom: 0;
    color: #fff;
    padding: 5px;
    }
.news-content p {margin-bottom: 6px;max-height: 40px;overflow-y: scroll;}
.news-content p::-webkit-scrollbar {
  width: 4px;
  height: 11px;
  background: #ccc;
  border-radius: 70px;
}
.innerSection .news-blk:hover {
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 1px, rgb(0 0 0 / 5%) 0px 4px 2px, rgb(0 0 0 / 5%) 0px 8px 4px, rgb(0 0 0 / 5%) 0px 16px 8px, rgb(0 0 0 / 5%) 0px 32px 16px;
}
.news-head h2 span {
    display: block;
    color: #153168;
    font-size: 18px;
    text-align: center;
}
.newsCount:nth-child(4n+1) > div {
    border-left: 3px solid #fff27e;
}
.newsCount:nth-child(4n+2) > div {
    border-left: 3px solid #ceb2ff;
}
.newsCount:nth-child(4n+3) > div {
    border-left: 3px solid #ff928f;
}
.newsCount:nth-child(4n+4) > div {
    border-left: 3px solid #ffb470;
}
.gallery-inner {
  background:linear-gradient(88deg, #464776, #3f406a);
  margin-bottom: 30px;
  box-shadow: 7px 6px 12px -4px #ddd;
  /* position: relative; */
  overflow: hidden;
  transition: all 0.5s ease-in;
}
.gallery-blk a {
  color: inherit;
}
.gallery-content {
  padding: 20px 15px;
  position: relative;
  background: #fff;
  width: 96%;
  margin: -30px auto 10px auto;
}

.gallery-blk img {
  width: auto;
  max-width: 100%;
  height: 90%;
  margin: auto;
  display: block;
  position: relative;
  box-shadow: 0px 0px 19px 0px #00000054;
  border: 7px solid #ffffff4f;
}

.gallery-inner:hover .gallery-content {
  bottom: 0;
}
.gallery-blk {
  height: 230px;
  object-fit: contain;
  position: relative;
  background: #ddd;
  overflow: hidden;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub-gallerycount .gallery-blk img {
  height: 100%;
}
.sub-gallerycount .gallery-blk {
  height: 200px;
  margin-bottom: 20px;
}
.gallery-blk .gallery-title h3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.gallery-title h3 {
  font-size: 18px;
  color: #978a5a;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  padding: 0px;
  margin-bottom: 15px;
  font-family: 'Poppins', sans-serif;
}
a.view-more {
  color: var(--black);
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
}
.contact-header p {
  display: flex;
  justify-content: center;
  align-items: center;
}
.gallery-title p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  line-height: 28px !important;
  font-size: 16px !important;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}
.gallery-date {
  display: flex;
}

.gallery-date h6 {
  color: #fff;
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 25px;
  background: #891c69;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
}
.gall-num h5 {
  background: transparent;
  color: #000;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 25px;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
}
.gallery-title {
  font-size: 20px;
  line-height: 30px;
  font-family: 'Poppins', sans-serif;
} 
.sub-gallerycount img {
  height: 200px;
  width: 100%;
  object-fit: cover;
  background: #f1f1;
  box-shadow: 0px 0px 3px 1px #0000004a;
}
.sub-gallerycount { 
  margin-bottom: 20px;
}
.eventsnewsbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: #fff;
  box-shadow: 2px 0px 8px 0px #ccc;
  margin-bottom: 20px;
}

.newsboximg {
  width: 40%;
  margin-right: 10px;
}

.newsboxdesc {
  width: 58%;
}
.newspgh {
  height: 150px;
  overflow-y: scroll;
  padding-top: 15px;
  margin-top: 10px;
  padding-right: 10px;
}
.uniformimg {
  height: auto;
  margin-bottom: 20px;
}
.newsboximg img {
  border: 10px solid #fff;
  height: 190px;
  object-fit: contain;
  background: #f1f1f1;
  width: 100%;
}
.newsdate {
  display: flex;
  justify-content: space-between;
  padding: 8px 5px;
}
.newspgh p {
  font-size: 15px;
  line-height: 30px;
}
.newsdate a {
  background: #851764;
  border-radius: 5px 3px 6px 0px;
  color: #fff;
  padding: 2px 5px;
}
.newsdate span {
  font-size: 14px;
  line-height: 30px;
}
.event-logo {
  height: 320px!important;
}
i {
  position: relative;
  top: -2px;
}
.newspgh h5 {
  font-family: 'Roboto',sans-serif;
  color: #000000ad;
  line-height: 30px;
  font-size: 18px;
}
.newspgh::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.newspgh::-webkit-scrollbar-thumb {
  background-color: var(--maroon);
}
.newspgh::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #F5F5F5;
}
.gallery-blks img {
  height: 230px;
  width: 100%;
  object-fit: cover;
}
.magnify-modal {
  position: fixed !important;
  z-index: 999999999 !important;
  padding-top: 10px !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: auto !important;
  background-color: rgba(0, 0, 0, 0.8509803922) !important
}

.magnify-header .magnify-toolbar {
  float: right;
  position: relative;
  z-index: 9
}

.magnify-stage {
  position: absolute;
  top: 40px;
  right: 10px;
  bottom: 40px;
  left: 10px;
  z-index: 1;
  background: #0000000a;
  overflow: hidden
}

.magnify-image {
  position: relative;
  display: inline-block;
  border: 5px solid #fff
}

.magnify-image.image-ready {
  max-width: 100%;
  max-height: 100%
}

.magnify-footer {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  color: #fff;
  text-align: center
}

.magnify-footer .magnify-toolbar {
  display: inline-block
}

.magnify-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  background: #055a91;
}

.magnify-button:hover {
  color: #fff;
  background-color: #e00000
}

.magnify-button-close:hover {
  background-color: #e00000
}

.magnify-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: #333
}

.magnify-loader::before {
  content: '';
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  -webkit-animation: magnifyLoading 1s infinite linear;
  animation: magnifyLoading 1s infinite linear
}

.magnify-loader::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  overflow: hidden
}

@-webkit-keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}

@keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}
.innerSection p {
  font-size: 16px;
  line-height: 30px;
}
.mesdeskimg h3 {
  color: #cc73af;
  margin-bottom: 8px;
  font-size: 18px;
  margin-top: 30px;
  line-height: 30px;
}
.mesdeskimg h5 {
  font-size: 16px;
}
.innerSection h4 {
  font-size: 20px;
  color: #2f3050;
  font-weight: 600;
  line-height: 33px;
}
.left-img {
  border: 10px solid #fff;
  box-shadow: 1px 5px 8px 0px #ccc;
  border-radius: 4px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.tcblock {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
} .tc {
  width: 49%;
  margin-bottom: 30px;
} .tcblock iframe {
  width: 49%;
  border: 0px solid #fff;
    box-shadow: 0px 0px 0px #ddd;
} .tc label {
  color: #000;
  width: 100%;
  margin-bottom: 0;
} .tc label p {
  margin-bottom: 0px;
} .tc .form-group {
  margin-bottom: 20px;
} .form-control:focus {
  box-shadow: none;
  border: 1px solid #ccc;
} a.viewdetailsbtn {
  color: #fff;
  font-size: 14px;
} .viewdetailsbtn {
  padding: 10px 15px;
  text-decoration: none;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
  border: none;
  background:linear-gradient(131deg, #8d3873, #5eaed7);
  transition: all 0.2s ease-in;
} .viewdetailsbtn:hover {
  background:linear-gradient(131deg, #5eaed7, #8d3873);
}
.cbsebtn .button.light.padding {
  right: 0;
  left: 0; 
  bottom: 0px; 
  position: relative;
}
.cbsebtn .button:before{
  width: 100%;
}
.message-block.cbsebtn {
  padding: 9px 0px;
  bottom: 11px; 
}
.verticaltabs .nav-pills .nav-link.active {
  background: #589ec1;
  color: #fff;
}
.nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: 0.25rem;
    background: #b5d7f9;
    margin: 0px 5px;
    padding: 2px 12px;
    color: #000000bd;
}
#sidebar a.active {
  color: #ff0000;
}
.nav-tabs li button {
  color: #4e4e4e;
}
.verticaltabs {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.verticaltabs .tab-content {
  background: #f1f1f1;
  padding: 15px 12px;
  width: 79%;
  border-left: none;
  min-height: 346px;
  padding-top: 20px;
  margin-left: 10px;
}
.infra_sec img {
  border: 10px solid #fff;
  margin-bottom: 15px;
  box-shadow: 0px 0px 5px 1px #ccc;
  border-radius: 5px;
}
.verticaltabs .nav-pills {border: 1px solid #eeeeee;background-color: #eeeeee;width: 20%; }

.verticaltabs .nav-pills .nav-link {
  display: block;
  background-color: inherit;
  color: black;
  padding: 13px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 14px;
  line-height: 16px;
  border-bottom: 1px solid #d8d8d8;
  margin: 0px 0px;
  border-radius: 0;
}
.verticaltabs h3 {
  font-weight: 500;
  text-align: left;
  font-size: 20px !important;
  margin-bottom: 15px;
}
.verticaltabs p {
  text-align: center;
  line-height: 45px;
  font-size: 18px;
}
.activitessec img {
  width: 100%;
  height: 210px;
}
.popup {
  padding-right: 0 !important;
}
.popup .carousel-control-prev-icon { background: url('https://webapi.entab.info/api/image/TPSP/public/Images/lefticn.png') no-repeat !important; }
.popup .carousel-control-next-icon { background: url('https://webapi.entab.info/api/image/TPSP/public/Images/righticn.png') no-repeat !important; }
.popup .carousel-control-next, .popup .carousel-control-prev { width: auto; }
.popup .carousel-control-next { right: -15px; }
.popup .modal-dialog { min-width:40%; transform: translateY(-100px); }
.carousel-item .modal-header { background: #0a4685; padding: 13px 0 0 }
.popup .modal-dialog img { width: 100%; object-fit: contain; }
.carousel-item .modal-body h1 {padding-bottom: 0; text-align: center; font-size: 17px; color: #000; overflow: hidden; font-weight: 600; text-transform: capitalize; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; margin-bottom: 10px; line-height: 28px; }
.carousel-item .modal-dialog .modal-header .btn-close { color: #fff; padding-right: 31px }
.carousel-item .modalcard .card-body { padding: 10px }
.carousel-item .modalcard .card-body p { display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; line-height: 35px; text-align: center; padding-top: 10px; line-height: 26px; }
.carousel-item .modalcard { border: none }
.popup .modal-content { padding: 0 0 10px }
.carousel-item button.btn-close:focus { box-shadow: none }
.carousel-item a.btn.btn-primary.btn-sm { border-radius: 6px;
  box-shadow: -2px 14px 11px -9px #0000008a; 
  transition: .5s; background: #5c1547; border: #5c1547; margin: 0 10px; color: #fff!important ;     padding: 10px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;}
.carousel-item a.btn.btn-primary.btn-sm:focus { box-shadow: none; border: 1px solid #5c1547; }
.carousel-item a.btn.btn-primary.btn-sm:hover {
  background: linear-gradient(0deg,#5c1547,#f56c6c);
}
.carousel-item button.btn-close {position: absolute;z-index: 999;background-color: #5c1547;right: 0;}
.carousel-item .btn-close { background: url('https://webapi.entab.info/api/image/TPSP/public/Images/closeicn.png') no-repeat; opacity: 1; padding: 10px; background-position: center; border-radius: 0; }
.modal-bottom { display: flex; flex-direction: row; justify-content: center; align-items: center; margin-top: 30px; }
span.bounce1 img { width: 22px; }
.OnlineR a { color: #fff; }
a.parantlogo { padding: 2px 10px; }
.OnlineR { position: fixed; right: 0; z-index: 99; text-align: center; color: #fff; top: 40%; font-family: Open Sans, sans-serif; }
@keyframes bounce1 { 
  0% {
    background-color: #a3237c;
}

33% {
    background-color: #61b0da;
}
66% {
    background-color: #a3237c;
}
100% {
    background-color: #61b0da;
}
}
.bounce1 { border-radius: 25px 0px 0px 25px; color: #fff; font-size: 14px; font-weight: 700; text-align: center; animation: bounce1 1s infinite; padding: 10px 12px; }
.footervisit {
  position: relative;
}
.visitors_count {
  position: absolute;
  left: 0px;
  bottom:-28px;
  z-index: 9999;
  width: 229px; 
  border: none;
}

.visitors_count h3 {
  background: #ffffff;
  margin: 0px;
  color: #8d3772;
  text-align: center;
  line-height: 30px;
  font-size: 15px;
  font-weight: bold;
}

.visitors_count h4 {
  background: #5a9fc3;
  margin: 0px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  font-size: 17px;
}

#myImg, #myImg1, #myImg2 {
  width: 100%;
  border: 5px solid rgba(158, 158, 158, 0.1);
  background: #eaeaea;
  cursor: pointer;
  transition: 0.3s;
}
.erp_sec img { 
  display: inline-block;
}
.erp_sec h4.imgheading {
  text-align: center;
}
.assistance {
  background: #eaeeff;
  padding: 20px;
  margin-top: 30px;
}
.erp_sec h4 {
  text-align: center;
  line-height: 40px;
  font-size: 17px;
  margin-bottom: 0;
  margin-top: 15px;
  font-weight: 700;
  font-family:"Inter", sans-serif;
}

.assistance {
  background:rgb(230 243 255);
  padding: 13px;
  border: solid 1px #ddd;
  margin-top: 20px;
}
.erpbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.erpboxdesc {
  width: 72%;
}

.erpboximg {
  width: 26%;
}

.erpboxdescpopup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
 
.erppopup {
  width: 30%;
  margin: 10px;
}

.imgheading a { 
  text-align: center;
  width: 100%;
  display: block;
  font-size: 20px;
  padding: 10px 0;
}

.erppopup .modal-body h5 {
  color: #000;
  text-align: center;
  padding-top: 20px;
}
.gal-img .description span  {
width: 100%;
display: flex;
justify-content: center;
opacity: 0;
align-items: center;
}
.gal-img .description span img {
  width: auto !important;
  height: auto !important;
  margin-top: 10px;
}
.kindergal {
  padding: 50px 0px;
  background: url('https://webapi.entab.info/api/image/TPSP/public/Images/kinderbg.jpg') no-repeat center bottom;
  background-size: cover;
}
.form-group { 
  width: 48%;
  margin-right: 17px;
}
.formdiv {
  display: flex;
  flex-wrap: wrap;
}
.selectclass {
  width: 100% !important;
}
.error {
  margin-bottom: 10px;
  margin-top: 5px;
  color: red;
  font-size: 13px;
}
form.careerform-feilds >div {
  width: 49%;
  margin-right: 10px;
  margin-bottom:10px;
  margin-top: 0;
}
form.careerform-feilds >div label {
  margin-top: 5px;
  margin-bottom: 5px;
}
form.careerform-feilds {
  display: flex;
  
  background: #f1f1f1;
  padding: 30px;
  box-shadow: 0px 0px 8px 1px #ccccccc9;
  border-radius: 3px;
  flex-wrap: wrap;
}
form.careerform-feilds .submit input {
  margin: 0;
  margin-top: 20px;
  background: #579cc0;
  border: 0;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
}
.form-heading h6 {
  color: #000;
}
.qrcode img {
  width: 160px;
  margin: 20px auto 0 auto;
  display: block;
}

.qrcode {
  width: 100% !important;
}
.primarydiv{width: 100% !important}
.campuslogo {
  position: fixed;
  right: 0px;
  bottom: 10px;
  z-index: 99;
}
.campuslogo img {
  width: 100px;
}
@media(max-width: 1600px){
  .contact-tab>div {
    margin-right: 15px;
}
  .contact-tab { 
    margin-left: 25px;
  }
  .main-slider .slide-content h2 {
    font-size: 33px;
}
.facilities > div, .news-event > div, .gallery > div{
  width: 95%;
}
.branch .branch-blk {
  width: 94%;
}
}
@media(max-width: 1400px){
  p.link { 
    width: 100%;
}
  .quick-link ul { 
    width: 45%;
  }
  .app-cont {
    width: 50%;
}
  .contact-tab>div {
    margin-right: 0;
}
  .contact-tab {
    margin-left: 0;
    justify-content: space-evenly;
    padding-right: 0;
}
  .gal-img { 
    height: 250px;
}
  .main-slider img {
    height: auto;
}
  .main-slider button.owl-prev span, .main-slider button.owl-next span { 
    width: 70px;
    height: 70px; 
    padding-bottom: 6px; 
}
.logo img {
  width: 240px;
}
  .button {
    padding: 2px 4px 2px 12px;
}
.online-reg img {
  width: 160px;
}
.button.padding {
  padding: 9px 14px;
}
.menu-tab { 
  top: 25px;
  right: 20px
  
}
.contact-tab { 
  width: 92%; 
}
  p {
    font-size: 14px;
}
.imp-link h4 { 
  font-size: 20px;
}
.news {
  background: #F5F5F5;
  padding: 30px 15px;
  border-radius: 16px;
  box-shadow: 0px 43px 40px -55px #000;
} .title h3 {
  font-size: 20px;
}
.news-event .title.center >div:nth-child(1) {
  width: 77%;
}
.footer img {
  width: 170px;
  margin: auto;
  display: block;
}
.branch .branch-blk p {
  font-size: 13px;
}
.branch .branch-blk .button.light.padding { 
  right: 20px;
}

}
@media(max-width: 1200px){
  .cont-dtl {
    margin-top: 20px;
}
  form.careerform-feilds >div {
    width: 48%;
  }
  .verticaltabs .tab-content { 
    width: 78%;
  }
  .newsboximg {
    width: 100%;
  }
  .newsboxdesc {
    width: 100%;
    padding: 0px 10px;
}
  .logo img.img-fluid {
    margin: 20px auto;
    display: block;
    padding-left: 0;
}
  .message > div,.about-us > div {
    width: 95%;
    margin: auto;
}
  .quick-link ul { 
    height: 100px; 
  }
  .branch {
    margin-top: 0;
}
.header-blk {
  width: 98%;
  margin: auto;
}
.button { 
  padding: 2px 7px 2px 8px; 
}
.main-slider .slide-content h2 {
  font-size: 26px;
}
.contact-header{
  font-size: 13px;  
}
.contact-header img {
  width: 28px;
  margin-right: 8px;
}
.highlihgt { 
  padding-left: 100px;
}
.news { 
  padding: 20px 20px;  
}
.main-slider button.owl-prev span, .main-slider button.owl-next span {  
  width: 50px;
  height: 50px; 
  padding-bottom: 6px; 
}
}
 

@media(max-width: 991px){
  form.careerform-feilds >div {
    width: 100%;
}
  .verticaltabs .nav-pills { 
    width: 100%;
    margin-bottom: 10px;
}
  .verticaltabs .tab-content {
    width: 100%;
    margin-left: 0;
}
  .tc {
    width:100%;
  }
  .tcblock iframe {
    width: 100%;
}

  .menu ul.submenu { 
    width: 90%; 
}
.submenu >li{
  width: 32%
}
  .main-slider .slide-content h2 {
    font-size: 18px;
}
.events {
  width: 100%; 
}

.news {
  margin-bottom: 30px;
}
  .highlihgt {
    padding-left: 50px;
}
.contact-header { 
  right: 40px; 
}
.imp-link .imp-img {
  width: 70px;
  height: 70px; 
}
.imp-link img {
  width: 33px; 
}
.imp-link h4 {
  font-size: 14px;
}
}
@media(max-width: 767px){
  .contact-form input {
    width: 100%;
    height: 40px;
    border: solid 1px #ddd;
    padding-left: 10px;
    float: left;
    margin-right: 10px;
    margin-bottom: 20px;
}
  ul.submenu > li {
    width: 100%;
  }
  .menu > div {
    position: absolute;
  }
  .visitors_count {
    position: fixed; 
    bottom: 0;
  }
  .left-img { 
    margin-left: 0;
    width: 100%;
  }
  .main-slider .slide-content { 
    left: 5px;
    bottom: 0;
  }
  .message .about-virtual {
    margin-top: 0;
}
  .contact-header {
    right: 5px;
    bottom: 40px;
}
  .highlihgt {
    background: #000;
    position: static; 
    margin: 0;
}
.quick-link{
  border-right: 0px;
}
  .gallery {   
    
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .facilities, .about-us{
    
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .contact-tab {
    width: 100%;
    flex-wrap: wrap;
}
.logo img.img-fluid {
  margin: 20px auto;
  display: block;
  width: 50%;
}
  .imp-link .d-flex {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}
.imp-link {
  margin: 40px 0;
}
  .quick-link ul {
    height: 200px;
    border-right: 0px;
}
.message .row {
  flex-direction: column-reverse;
}
.message .title {
  text-align: center;
}
}
@media(max-width : 600px){
  p.link {
    width: max-content;
}
  .quick-link { 
    flex-wrap: wrap;
}
  .quick-link ul {
    width: 100%;
}
.app-cont {
  width: 100%;
}

  .form-section .form-block .form-feilds > div {
    width: 100%;
}
  .contact-tab .button {
    margin: 10px  0px;
    font-size: 13px;
}
.button .img { 
  margin-left: 6px; 
}
.online-reg img {
  width: 133px;
}
  .logo img.img-fluid {
    margin: 0px 0 10px;
    display: block;
    width: 50%;
}
  .submenu >li {
    width: 50%;
}
.menu .logo {
  width: 100% !important;}
  .message-block .message-image {
    width: 100%;
}
.contact-header {
  right: 40px;
  display: none;
}

.message-block {
    flex-direction: column;
}

.message-content {
    width: 100%;
}
.message{
  background: unset;
}
.main-slider .owl-nav, .main-slider .slide-content{
  display: none !important;
} 
.highlihgt {
  padding-left: 10px;
}
}
@media(max-width: 400px){
  .submenu >li {
    width: 100%;
}
  .quick-link ul {
    height: auto;
    border-right: 0px;
}
}

